<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "Hoşgeldiniz!",
    titleTemplate: "%s | FİN-MAN Eğitim",
    htmlAttrs: { lang: "tr" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
};
</script>

<style lang="scss"></style>
