import Vue from "vue";
import Vuetify from "vuetify/lib";
import tr from "vuetify/es5/locale/tr";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: "#3f8fa0",
        secondary: "#3f8fa0",
        accent: "#27e6df"
      }
    }
  },
  lang: {
    locales: { tr },
    current: "tr"
  }
});
